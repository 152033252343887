/* Variables */
/* Reset */
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,600|Playfair+Display:400,700");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

img {
  max-width: 100%;
  height: auto; }

/* Divs display block by default */
div {
  display: block; }

/* Clearfix */
header:before, header:after, nav:before, nav:after, main:before, main:after,
section:before, section:after, article:before, article:after, aside:before, aside:after,
footer:before, footer:after, .container:before, .container:after,
.clearfix:before, .clearfix:after {
  content: "";
  display: table; }

header:after, nav:after, main:after, section:after, article:after, aside:after,
footer:after, .container:after, .clearfix:after {
  clear: both; }

header, nav, main, section, article, aside, footer, .container, .clearfix {
  zoom: 1;
  /* For IE 6/7 (trigger hasLayout) */ }

.is-hidden {
  display: none; }

/* Fonts */
/* HTML Styles */
body {
  margin: 0;
  padding: 16px 0 0 0;
  background: #fff;
  color: #000;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px; }

.logo {
  float: left; }
  .logo img {
    width: auto;
    height: auto;
    max-width: 300px; }

/* Main */
main {
  padding: 0 0 88px 0; }

main.main-padded-med {
  padding-top: 60px; }

main.main-padded-lg {
  padding-top: 80px; }

main.main-pad-bottom-none {
  padding-bottom: 0px; }

main.main-pad-bottom-half {
  padding-bottom: 24px; }

.main-shaded {
  padding: 48px 0 18px;
  margin-bottom: 50px;
  background: #f3f3f3; }

/* Typography */
h1, h2, h3, h4, h5, p, ul, ol, blockquote {
  margin-bottom: 30px;
  line-height: 1.4em; }

h1 {
  font-family: 'Playfair Display', serif;
  font-size: 36px;
  font-weight: 700; }

h2 {
  font-size: 24px;
  font-weight: 400; }

h3 {
  font-family: 'Playfair Display', serif;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0.02em; }

h4 {
  font-size: 20px;
  font-weight: 600; }

h5 {
  font-size: 20px;
  font-weight: 400;
  color: #69737B; }

p {
  font-size: 16px;
  font-weight: 300; }

p.intro {
  color: #69737B;
  font-size: 19px;
  line-height: 1.5em;
  margin-bottom: 40px; }

p.intro-heavy {
  font-weight: 400; }

p.small, p.caption {
  font-size: 14px;
  line-height: 1.7em;
  font-weight: 400; }

p.caption {
  font-size: 15px;
  padding: 0;
  margin-top: 0;
  margin-bottom: 60px; }

blockquote {
  font-size: 17px;
  text-align: center;
  color: #69737B;
  margin: 0 0 24px 0;
  font-weight: 400;
  line-height: 1.80em; }

cite {
  display: block;
  font-size: 15px;
  color: #222;
  text-align: center;
  font-weight: 400;
  margin: 0 0 36px 0; }

cite.mrg-bottom {
  margin-bottom: 72px; }

a {
  color: #000;
  text-decoration: underline; }

a.link-button {
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  color: #000;
  background: #fff;
  text-align: center;
  text-transform: uppercase;
  padding: 15px 36px;
  border: solid 1px #000;
  border-radius: 99px;
  text-decoration: none; }
  a.link-button:hover {
    background: #f8f8f8; }

a.link-button-reversed {
  color: #fff;
  background: #000; }
  a.link-button-reversed:hover {
    background: #222; }

strong {
  font-weight: 700; }

.is-centered {
  text-align: center; }

.divider {
  margin: 0 6px;
  color: #999; }

hr {
  display: block;
  clear: both;
  height: 1px;
  overflow: hidden;
  color: #aaa;
  background: #aaa;
  border: none;
  margin: 0 0 40px 0; }

/* Footer */
footer {
  padding: 30px 0 80px;
  text-align: center;
  font-size: 14px;
  font-family: 'Playfair Display', serif; }
  footer .divider {
    margin: 0 10px;
    color: #ccc; }

/* Header */
@media screen and (min-width: 680px) {
  body.headroom-ready {
    padding: 79px 0 0; } }

.site-header {
  background: #000;
  padding: 20px 0; }
  .home .site-header {
    display: none; }
  @media screen and (min-width: 680px) {
    .home .site-header.is-active {
      display: block; }
    .site-header.headroom {
      /*border-top: 16px solid #fff;*/
      position: fixed;
      top: 0;
      transition: transform 250ms linear;
      width: 100%;
      will-change: transform; }
      .site-header.headroom.headroom--unpinned {
        transform: translateY(-100%); }
      .site-header.headroom.headroom--pinned {
        transform: translateY(0); } }

.nav-toggle {
  display: none; }
  @media screen and (max-width: 680px) {
    .js .nav-toggle {
      float: right;
      background: none;
      border: 1px solid #fff;
      border-radius: 5px;
      box-shadow: none;
      color: #fff;
      display: inline-block;
      font-size: 15px;
      margin: 0 0;
      padding: 8px 14px; } }

nav#nav_primary {
  float: right;
  width: auto;
  margin: 0 0;
  padding: 8px 0 0;
  font-weight: bold;
  text-transform: uppercase; }
  nav#nav_primary ul {
    list-style: none;
    margin: 0;
    padding: 0; }
    nav#nav_primary ul li {
      display: inline-block;
      margin: 0;
      padding: 0; }
      nav#nav_primary ul li + li {
        margin: 0 0 0 28px; }
  nav#nav_primary a {
    font-size: 13px;
    color: #fff;
    display: block;
    text-decoration: none;
    margin: 0;
    transition: all linear 0.1s; }
    nav#nav_primary a:hover {
      color: #ccc; }
  @media screen and (max-width: 680px) {
    .js nav#nav_primary {
      background: #000;
      bottom: 0;
      left: -100%;
      min-width: 320px;
      padding: 0s;
      position: fixed;
      text-align: left;
      top: 0;
      transition: all .2s;
      width: 100%;
      z-index: 2; }
      .js nav#nav_primary .nav-toggle {
        margin: 16px; }
      .js nav#nav_primary ul {
        padding: 16px; }
        .js nav#nav_primary ul li {
          display: block; }
          .js nav#nav_primary ul li + li {
            margin: 16px 0 0; }
      .js nav#nav_primary.is-open {
        left: 0; } }

/* Active Nav States */
body.residential nav#nav_primary a.nav-residential,
body.commercial nav#nav_primary a.nav-commercial,
body.on-the-market nav#nav_primary a.nav-on-the-market,
body.about nav#nav_primary a.nav-about,
body.team nav#nav_primary a.nav-team,
body.contact nav#nav_primary a.nav-contact {
  color: #bbb; }

/* Modules */
.container {
  margin: 0 auto;
  padding: 0 0;
  width: auto;
  max-width: 930px;
  box-sizing: border-box; }

.container-wide {
  max-width: 1100px; }

.container-pad-top {
  padding-top: 60px; }

.hero {
  height: 600px; }

.hero-project-ritz {
  background: url(../img/projects/ritz-carlton/hero-banner.jpg) center center no-repeat;
  background-size: cover; }

.hero-project-the-w {
  background: url(../img/projects/the-w/hero-banner.jpg) center center no-repeat;
  background-size: cover; }

.hero-project-the-jordan-lofts {
  background: url(../img/projects/the-jordan-lofts/hero-banner.jpg) center center no-repeat;
  background-size: cover; }

.hero-project-the-factory-on-wareham {
  background: url(../img/projects/the-factory-on-wareham/hero-banner.jpg) center center no-repeat;
  background-size: cover; }

.hero-project-the-bradley-mansion {
  background: url(../img/projects/the-bradley-mansion/hero-banner.jpg) center center no-repeat;
  background-size: cover; }

.hero-project-four-51 {
  background: url(../img/projects/four-51/hero-banner.jpg) center center no-repeat;
  background-size: cover; }

.hero-project-stunning-south-end-townhouse {
  background: url(../img/projects/stunning-south-end-townhouse/hero-banner.jpg) center center no-repeat;
  background-size: cover; }

.hero-project-sophisticated-back-bay-townhouse {
  background: url(../img/projects/sophisticated-back-bay-townhouse/hero-banner.jpg) center center no-repeat;
  background-size: cover; }

.hero-project-beacon-hill-single-family {
  background: url(../img/projects/beacon-hill-single-family/hero-banner.jpg) center center no-repeat;
  background-size: cover; }

.hero-project-beacon-hill-townhouse {
  background: url(../img/projects/gorgeous-beacon-hill-townhouse/hero-banner.jpg) center center no-repeat;
  background-size: cover; }

.hero-project-exquisite-back-bay-condos {
  background: url(../img/projects/exquisite-back-bay-condos/hero-banner.jpg) center center no-repeat;
  background-size: cover; }

.hero-residential-commercial {
  background: url("../img/residential/hero-banner-residential.jpg") center center no-repeat;
  background-size: cover; }

.project-card, .team-name-card {
  display: inline-block;
  margin: 240px 0 0 0;
  width: 430px;
  padding: 36px 32px 42px;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.9); }
  .project-card h1, .team-name-card h1 {
    margin-bottom: 0; }
  .project-card h1.mrg-bottom, .team-name-card h1.mrg-bottom {
    margin-bottom: 24px; }
  .project-card h1.h1-small, .team-name-card h1.h1-small {
    font-size: 32px; }
  .project-card p, .team-name-card p {
    font-size: 19px;
    margin: 0 20px 0 0;
    color: #69737B; }
  .project-card p.p-small, .team-name-card p.p-small {
    font-size: 17px; }

.project-card-tall {
  margin-top: 80px; }

.team-name-card {
  width: auto;
  padding: 32px 32px; }
  .team-name-card h1 {
    margin-bottom: 0; }

.team-card-right {
  display: block;
  float: right; }

.work-with-us .wwu-top, .project-stats .wwu-top {
  background: url(../img/work-with-us.png) 50% 0 no-repeat;
  background-size: 100%;
  height: 20px;
  overflow: hidden; }
.work-with-us .wwu-body, .project-stats .wwu-body {
  border: solid 1px #b4b9bd;
  border-top: none;
  padding: 50px 70px 70px;
  text-align: center; }
.work-with-us h3, .project-stats h3 {
  margin-bottom: 36px; }
.work-with-us p, .project-stats p {
  margin-bottom: 36px; }
.work-with-us ul, .project-stats ul {
  margin: 0;
  padding: 0;
  list-style-type: none; }
.work-with-us li, .project-stats li {
  display: block;
  float: left;
  width: 30.3333333%;
  margin: 0 1.5%;
  padding: 0;
  text-align: center;
  font-size: 15px; }
  .work-with-us li strong, .project-stats li strong {
    display: block;
    text-transform: uppercase;
    margin: 0 0 22px;
    font-size: 13px; }

.project-stats {
  margin-bottom: 70px; }
  .project-stats .wwu-body {
    padding: 50px 70px 60px; }

/* Card Grid - 2, 3, 4 columns with wide gutters */
.cards-2up, .cards-3up, .cards-4up {
  margin-bottom: 54px; }
  .cards-2up .card, .cards-3up .card, .cards-4up .card {
    clear: both;
    float: left;
    width: 48.2%;
    margin: 0 0 32px 0; }
  .cards-2up .card-right, .cards-3up .card-right, .cards-4up .card-right {
    float: right;
    clear: none; }
  .cards-2up img, .cards-3up img, .cards-4up img {
    margin-bottom: 20px; }
  .cards-2up a img, .cards-3up a img, .cards-4up a img {
    transition: all linear 0.07s; }
  .cards-2up a:hover img, .cards-3up a:hover img, .cards-4up a:hover img {
    transform: scale(1.01); }
  .cards-2up .card-full-width, .cards-3up .card-full-width, .cards-4up .card-full-width {
    float: none;
    width: 100%; }
    .cards-2up .card-full-width img, .cards-3up .card-full-width img, .cards-4up .card-full-width img {
      margin-bottom: -3px; }
  .cards-2up .card-40, .cards-3up .card-40, .cards-4up .card-40 {
    width: 41.0%;
    margin-bottom: 7px; }
    .cards-2up .card-40 img, .cards-3up .card-40 img, .cards-4up .card-40 img {
      margin-bottom: 0; }
  .cards-2up .card-60, .cards-3up .card-60, .cards-4up .card-60 {
    width: 58.0%;
    margin-bottom: 7px; }
    .cards-2up .card-60 img, .cards-3up .card-60 img, .cards-4up .card-60 img {
      margin-bottom: 0; }
  .cards-2up .project-title, .cards-2up .team-title, .cards-3up .project-title, .cards-3up .team-title, .cards-4up .project-title, .cards-4up .team-title {
    display: block;
    font-size: 20px;
    line-height: 1.3em;
    font-family: 'Playfair Display', serif;
    font-weight: 700;
    text-decoration: none;
    margin-bottom: 18px; }
    .cards-2up .project-title:hover, .cards-2up .team-title:hover, .cards-3up .project-title:hover, .cards-3up .team-title:hover, .cards-4up .project-title:hover, .cards-4up .team-title:hover {
      text-decoration: underline; }
  .cards-2up .project-meta, .cards-2up .card-meta, .cards-3up .project-meta, .cards-3up .card-meta, .cards-4up .project-meta, .cards-4up .card-meta {
    font-size: 14px; }

body.team .cards-2up {
  margin-bottom: 12px; }
  body.team .cards-2up .card {
    margin-bottom: 36px; }
    body.team .cards-2up .card a.team-title {
      margin-bottom: 0; }

/* Flexbox Cards on Homepage */
.cards-3up-flex {
  margin-bottom: 0;
  display: flex;
  justify-content: space-between; }
  .cards-3up-flex .card {
    width: 30.33333333%;
    margin: 0 1.5%;
    padding: 0;
    background: #fff; }
  .cards-3up-flex .card-body {
    padding: 0 22px 24px; }
  .cards-3up-flex h3 {
    margin-bottom: 18px; }
  .cards-3up-flex p {
    font-size: 15px;
    line-height: 1.6em;
    margin: 0;
    color: #000; }
  .cards-3up-flex a {
    color: #09347A;
    text-decoration: underline;
    font-weight: 600; }

.cards-3up {
  margin: 0 -1.5% 6px -1.5%; }
  .cards-3up .card {
    clear: none;
    float: left;
    width: 30.333333333%;
    margin: 0 1.5% 24px; }

.cards-4up {
  margin: 0 -1.5% 12px -1.5%; }
  .cards-4up .card {
    clear: none;
    float: left;
    width: 22.0%;
    margin: 0 1.5% 24px;
    padding: 0; }
    .cards-4up .card .card-meta {
      display: block;
      min-height: 48px;
      line-height: 1.4em; }

.cards-mrg-bottom {
  margin-bottom: 40px; }

/* Project and Team Cards */
.cards-2up-flush {
  margin: 0 -0.5% 54px -0.5%; }
  .cards-2up-flush .card {
    clear: both;
    float: left;
    width: 49.0%;
    margin: 0 0.5% 7px 0.5%; }
  .cards-2up-flush .card-right {
    float: right;
    clear: none; }
  .cards-2up-flush .card-full-width {
    width: 99.0%; }
  .cards-2up-flush img {
    margin-bottom: 0px; }

.card-grid-mrg-none {
  margin-bottom: 0px; }

.home-section-division {
  background: url(../img/home/home-section-bg.jpg) 0 0 no-repeat;
  background-size: cover;
  padding: 80px 0;
  margin-top: 54px; }
  .home-section-division .container {
    max-width: 1020px; }
  .home-section-division h2 {
    margin: 0 0 60px 0;
    font-size: 34px;
    text-align: center;
    color: #fff;
    font-family: 'Playfair Display', serif;
    font-weight: 600; }

.featured-projects {
  margin: 70px 0 0; }

.featured-project-row {
  margin: 0 0 70px; }
  .featured-project-row h4 {
    font-family: 'Playfair Display', serif;
    font-size: 36px;
    font-weight: 600;
    margin-bottom: 24px; }
  .featured-project-row h5 {
    color: #69737B; }
  .featured-project-row img {
    float: left;
    width: 50%; }
    .featured-project-row img.right {
      float: right; }
  .featured-project-row .featured-project-card {
    display: block;
    float: right;
    width: 50%;
    margin: 0; }
    .featured-project-row .featured-project-card.left {
      float: left; }
  .featured-project-row .featured-project-card-content {
    box-sizing: border-box;
    padding: 64px 0 0 80px;
    max-width: 470px; }

/* Homepage Hero with Nav */
body.home.headroom-ready {
  padding-top: 0; }

header.home-hero-nav {
  height: auto;
  max-height: 560px;
  background: url(../img/home/home-hero-nav.jpg) center center no-repeat;
  background-size: cover; }
  header.home-hero-nav img.logo-home {
    display: block;
    margin: 96px auto 0 auto;
    max-width: 200px; }
  header.home-hero-nav nav#navigation_home {
    margin-top: 100px;
    margin-bottom: 32px; }
  header.home-hero-nav nav ul {
    display: block;
    margin: 0 auto;
    padding: 0;
    list-style-type: none;
    text-align: center; }
    header.home-hero-nav nav ul li {
      display: inline-block;
      text-transform: uppercase;
      margin: 0 13px;
      font-size: 14px;
      font-weight: 700; }
      header.home-hero-nav nav ul li:first-child {
        margin-left: 0; }
      header.home-hero-nav nav ul li:last-child {
        margin-right: 0; }
    header.home-hero-nav nav ul a {
      color: #fff;
      text-decoration: none;
      transition: all linear 0.1s; }
      header.home-hero-nav nav ul a:hover {
        text-decoration: underline; }

.map-container {
  padding-top: 26px;
  margin: 0 0 54px; }

.col-2 {
  float: left;
  width: 48.0%;
  margin: 0 2.0% 0 0; }

.contact-block {
  margin-bottom: 60px; }
  .contact-block p {
    font-size: 15px;
    margin: 0 0 26px;
    line-height: 1.625em; }
    .contact-block p strong {
      font-size: 88%;
      display: block;
      text-transform: uppercase;
      margin: 0 0 10px 0; }
    .contact-block p:last-child {
      margin-bottom: 0; }

.project-accolades {
  position: relative;
  min-height: 66px;
  margin: 0 0 48px 0; }
  .project-accolades .accolade-image {
    position: absolute;
    top: 0px;
    left: 0px; }
    .project-accolades .accolade-image img {
      max-width: 112px;
      height: auto; }
  .project-accolades .accolade-details {
    margin-left: 140px;
    padding-top: 28px;
    font-size: 110%;
    color: #000;
    font-weight: 500; }

/* Forms */
.modaal-wrapper .modaal-close {
  position: absolute;
  right: 5px;
  top: 5px; }
  .modaal-wrapper .modaal-close:after, .modaal-wrapper .modaal-close:before {
    background: #1D1D1B; }
@media screen and (min-width: 680px) {
  .modaal-wrapper .modaal-close {
    right: 30px;
    top: 30px; } }

.modaal-container {
  max-width: 930px; }
  .modaal-container .modaal-content-container {
    padding: 16px 26px; }
  @media screen and (min-width: 680px) {
    .modaal-container .modaal-content-container {
      padding: 60px 70px; } }

.form-row {
  margin: 0; }
  .form-row + .form-row {
    margin: 16px 0 0; }
  @media screen and (min-width: 680px) {
    .form-row {
      align-items: flex-start;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }
      .form-row + .form-row {
        margin: 40px 0 0; } }

.form-row__col {
  margin: 0;
  padding: 0; }
  .form-row__col + .form-row__col {
    margin: 16px 0 0; }
  @media screen and (min-width: 680px) {
    .form-row__col {
      flex: 1 0 auto; }
      .form-row__col.form-row__col--full {
        flex: 1; }
      .form-row__col + .form-row__col {
        margin: 0;
        padding: 0 0 0 70px; } }

label {
  color: #000;
  display: block;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.86px;
  margin: 0 0 12px;
  text-transform: uppercase; }

textarea,
input[type=text],
input[type=email],
input[type=tel] {
  background: #FFFFFF;
  border: 1px solid #69737B;
  font-size: 16px;
  padding: 12px 8px;
  width: 100%; }

button {
  background: #000;
  border: solid 1px #000;
  border-radius: 99px;
  color: #fff;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  padding: 15px 36px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  transition: background .2s; }
  button:focus, button:hover {
    background: #222; }

.form-thank-you {
  display: none; }

.form-error p {
  color: #cc0000;
  font-size: 15px;
  font-weight: 400;
  margin: 0 0 .5em; }
  .form-error p:last-child {
    margin-bottom: 2em; }

/* Tables */
/* Media Queries */
@media screen and (max-width: 1260px) {
  .featured-projects {
    margin: 54px 0 0; }

  .featured-project-row {
    margin: 0 0 60px; }
    .featured-project-row .featured-project-card-content {
      padding: 54px 0 0 80px; } }
@media screen and (max-width: 1230px) {
  .featured-project-row {
    margin: 0 0 56px; }
    .featured-project-row .featured-project-card-content {
      padding: 46px 0 0 70px; } }
@media screen and (max-width: 1180px) {
  .container-wide {
    max-width: 1040px;
    margin: 0 auto;
    padding: 0 0; }

  nav#nav_primary ul li + li {
    margin: 0 0 0 24px; }

  .featured-project-row {
    margin: 0 0 48px; }
    .featured-project-row .featured-project-card-content {
      padding: 50px 0 0 60px; }
      .featured-project-row .featured-project-card-content h4 {
        margin-bottom: 20px; }
      .featured-project-row .featured-project-card-content h5 {
        font-size: 18px;
        margin-bottom: 20px; } }
@media screen and (max-width: 1100px) {
  .container-wide {
    max-width: 100%;
    margin: 0 auto;
    padding: 0 30px; } }
@media screen and (max-width: 1080px) {
  .featured-project-row .featured-project-card-content {
    max-width: auto; } }
@media screen and (max-width: 1050px) {
  .featured-project-row {
    margin: 0 0 44px; }
    .featured-project-row .featured-project-card-content {
      padding: 32px 40px 0 40px; }
      .featured-project-row .featured-project-card-content h4 {
        font-size: 30px;
        margin-bottom: 18px; }
      .featured-project-row .featured-project-card-content h5 {
        font-size: 18px;
        margin-bottom: 18px; } }
@media screen and (max-width: 1020px) {
  nav#nav_primary ul li + li {
    margin: 0 0 0 20px; } }
@media screen and (max-width: 1000px) {
  .container {
    width: auto;
    margin: 0 auto;
    padding: 0 30px; }

  .container-pad-top {
    padding-top: 48px; } }
@media screen and (max-width: 980px) {
  header {
    padding: 22px 0; }

  .logo {
    float: none; }
    .logo img {
      display: block;
      margin: 0 auto; }

  nav#nav_primary {
    padding-top: 26px;
    float: none;
    text-align: center; }
    nav#nav_primary a {
      display: inline-block;
      float: none;
      margin: 0 20px; }
    nav#nav_primary a:first-child {
      margin-left: 0; }
    nav#nav_primary a:last-child {
      margin-right: 0; }

  /* Main */
  main {
    padding: 0 0 60px 0; }

  main.main-padded-med {
    padding-top: 36px; }

  main.main-padded-lg {
    padding-top: 72px; }

  .card .divider {
    margin: 0 4px; }

  h1 {
    font-size: 33px; }

  p.intro {
    font-size: 18px; }

  .container-pad-top {
    padding-top: 44px; }

  /* Project Page Styles */
  .hero {
    height: 500px; }

  .card-grid-flush {
    margin-bottom: 24px; }

  .project-card {
    margin: 200px 0 0 0;
    width: 430px;
    padding: 32px 30px 40px; }
    .project-card h1.mrg-bottom {
      margin-bottom: 22px; }
    .project-card h1.h1-small {
      font-size: 32px; }
    .project-card p {
      font-size: 18px; }
    .project-card p.p-small {
      font-size: 17px; }

  .project-card-tall {
    margin-top: 70px;
    width: 460px; }

  .team-name-card {
    padding: 28px 30px; }

  .project-stats {
    margin-bottom: 44px; }
    .project-stats .wwu-body {
      padding: 40px 60px 50px; }

  blockquote {
    font-size: 20px; }

  cite.mrg-bottom {
    margin-bottom: 50px; }

  .cards-3up-flex img {
    margin-bottom: 12px; }
  .cards-3up-flex .card {
    margin: 0 0 22px;
    padding: 0; }
  .cards-3up-flex .card-body {
    padding: 0 20px 22px; }
  .cards-3up-flex h3 {
    font-size: 20px;
    margin-bottom: 16px; }
  .cards-3up-flex p {
    font-size: 14px;
    line-height: 1.5em; }

  header.home-hero-nav {
    max-height: 496px; }
    header.home-hero-nav img.logo-home {
      margin-top: 76px;
      max-width: 180px; }
    header.home-hero-nav nav#navigation_home {
      margin-top: 100px;
      margin-bottom: 32px; }
    header.home-hero-nav nav ul li {
      margin: 0 11px; }

  .team-member {
    padding-top: 42px; }

  .team-member-details h1 {
    margin-bottom: 20px; } }
@media screen and (max-width: 960px) {
  .featured-project-row {
    margin: 0 0 40px; }
    .featured-project-row .featured-project-card-content {
      padding: 26px 32px 32px; }
      .featured-project-row .featured-project-card-content h4 {
        font-size: 28px;
        margin-bottom: 17px; }
      .featured-project-row .featured-project-card-content h5 {
        font-size: 17px;
        margin-bottom: 17px; } }
@media screen and (max-width: 930px) {
  .featured-project-row .featured-project-card-content {
    padding: 20px 30px 0px; }
    .featured-project-row .featured-project-card-content h4 {
      font-size: 27px;
      margin-bottom: 17px; }
    .featured-project-row .featured-project-card-content p {
      font-size: 15px;
      margin-bottom: 28px; } }
@media screen and (max-width: 879px) {
  .featured-project-row .featured-project-card-content {
    padding: 8px 30px 0px; }
    .featured-project-row .featured-project-card-content h4 {
      font-size: 26px;
      margin-bottom: 16px; }
    .featured-project-row .featured-project-card-content p {
      font-size: 15px;
      margin-bottom: 28px; }

  .cards-4up .card .team-title {
    font-size: 19px;
    margin-bottom: 16px; }

  main p.team-bio {
    margin-right: 3.0%;
    font-size: 1.04em; }

  main p.team-contact {
    margin-bottom: 48px; } }
@media screen and (max-width: 800px) {
  nav#nav_primary a {
    margin: 0 16px; }
  nav#nav_primary a:first-child {
    margin-left: 0; }
  nav#nav_primary a:last-child {
    margin-right: 0; }

  .work-with-us .wwu-top, .project-stats .wwu-top {
    background: url(../img/work-with-us-med.png) 50% 0 no-repeat;
    background-size: 100%; }
  .work-with-us li, .project-stats li {
    width: 31.3333333%;
    margin: 0 1.0%;
    font-size: 14px; }

  /* Project Page Styles */
  .hero {
    height: 400px; }

  .container-pad-top {
    padding-top: 36px; }

  .card-grid-flush {
    margin-bottom: 20px; }

  .project-card, .team-name-card {
    margin: 142px 0 0 0;
    width: 430px;
    padding: 30px 32px 38px; }
    .project-card h1, .team-name-card h1 {
      font-size: 27px; }
    .project-card h1.mrg-bottom, .team-name-card h1.mrg-bottom {
      margin-bottom: 20px; }
    .project-card h1.h1-small, .team-name-card h1.h1-small {
      font-size: 25px; }
    .project-card p, .team-name-card p {
      font-size: 17px;
      margin: 0 20px 0 0; }
    .project-card p.p-small, .team-name-card p.p-small {
      font-size: 16px; }

  .project-card-tall {
    margin-top: 76px;
    width: 550px; }

  .team-name-card {
    padding: 26px 30px; }

  .project-stats {
    margin-bottom: 40px; }
    .project-stats .wwu-body {
      padding: 36px 32px 42px; }

  blockquote {
    font-size: 16px; }

  cite {
    font-size: 14px; }

  cite.mrg-bottom {
    margin-bottom: 50px; }

  .cards-4up {
    margin: 0 -1.5% 6px -1.5%; }
    .cards-4up .card {
      width: 30.3333333%;
      margin: 0 1.5% 22px; } }
@media screen and (max-width: 768px) {
  .cards-3up-flex {
    display: block; }
    .cards-3up-flex .card {
      float: none;
      width: auto;
      margin: 0 0 28px 0; }
      .cards-3up-flex .card:last-child {
        margin-bottom: 0; }
    .cards-3up-flex img {
      width: 100%; }
    .cards-3up-flex .card-body {
      padding: 0 24px 26px; }
    .cards-3up-flex p {
      font-size: 16px;
      line-height: 1.6em; }

  .featured-project-row {
    margin-bottom: 48px; }
    .featured-project-row .featured-project-card-content {
      padding: 20px 40px 0; }

  main.main-padded-lg {
    padding-top: 60px; }

  header.home-hero-nav {
    max-height: 424px; }
    header.home-hero-nav img.logo-home {
      margin-top: 50px;
      max-width: 166px; }
    header.home-hero-nav nav#navigation_home {
      margin-top: 80px; }
    header.home-hero-nav nav ul li {
      margin: 0 10px; }
      header.home-hero-nav nav ul li:first-child {
        margin-left: 0; }
      header.home-hero-nav nav ul li:last-child {
        margin-right: 0; } }
@media screen and (max-width: 690px) {
  main {
    padding: 0 0 50px 0; }

  main.main-padded-med {
    padding-top: 30px; }

  main.main-padded-lg {
    padding-top: 50px; }

  nav#nav_primary a {
    margin: 0 13px; }
  nav#nav_primary a:first-child {
    margin-left: 0; }
  nav#nav_primary a:last-child {
    margin-right: 0; }

  .cards-2up {
    margin-bottom: 20px; }

  .cards-3up .project-title {
    font-size: 19px; }

  .featured-project-row img {
    float: none;
    width: 100%;
    max-width: auto; }
    .featured-project-row img.right {
      float: none; }
  .featured-project-row .featured-project-card {
    float: none;
    width: auto;
    max-width: auto;
    margin: 0; }
    .featured-project-row .featured-project-card.left {
      float: none; }

  .work-with-us .wwu-body, .project-stats .wwu-body {
    padding: 40px 60px 64px; } }
@media screen and (max-width: 680px) {
  body {
    padding: 0; }

  .site-header {
    padding: 18px 0; }

  .logo img {
    float: left;
    margin-top: 3px;
    max-width: 290px; }

  .team-member {
    padding-top: 0px; }

  .team-member-details h1 {
    margin-bottom: 18px; }
  .team-member-details h3 {
    margin-bottom: 26px; }

  main p.team-contact {
    margin-bottom: 40px; } }
@media screen and (max-width: 670px) {
  h1 {
    font-size: 30px; }

  p.intro {
    font-size: 17px; }

  .home-section-division {
    padding: 64px 0;
    margin-top: 48px; }
    .home-section-division h2 {
      margin: 0 0 48px 0;
      font-size: 30px; }

  .cards-2up .card, .cards-3up-flex .card {
    clear: both;
    float: none;
    width: 100%;
    margin: 0 0 36px 0; }
  .cards-2up .card-right, .cards-3up-flex .card-right {
    float: none;
    clear: both; }
  .cards-2up .card-full-width, .cards-3up-flex .card-full-width {
    margin-bottom: 0; }
  .cards-2up img, .cards-3up-flex img {
    margin-bottom: 16px; }
  .cards-2up .card-flush, .cards-3up-flex .card-flush {
    float: none;
    clear: both;
    margin-bottom: 0; }
    .cards-2up .card-flush img, .cards-3up-flex .card-flush img {
      width: 100%; }

  body.team .cards-2up {
    margin-bottom: 0px; }
    body.team .cards-2up .card {
      clear: none;
      float: left;
      width: 48.0%; }
    body.team .cards-2up .card-right {
      float: right; }

  .card-grid-flush {
    margin-bottom: 40px; }

  .cards-2up .card-40, .cards-3up .card-40, .cards-4up .card-40 {
    margin-bottom: 16px; }
  .cards-2up .card-60, .cards-3up .card-60, .cards-4up .card-60 {
    margin-bottom: 16px; }

  /* Project Page Styles */
  .hero {
    height: 320px; }

  .project-card, .team-name-card {
    margin: 100px 0 0 0;
    width: 430px;
    padding: 30px 32px 40px; }
    .project-card h1, .team-name-card h1 {
      font-size: 27px; }
    .project-card h1.mrg-bottom, .team-name-card h1.mrg-bottom {
      margin-bottom: 20px; }
    .project-card h1.h1-small, .team-name-card h1.h1-small {
      font-size: 25px; }
    .project-card p, .team-name-card p {
      font-size: 17px;
      margin: 0 20px 0 0;
      color: #111; }
    .project-card p.p-small, .team-name-card p.p-small {
      font-size: 16px; }

  .project-card-tall {
    margin-top: 34px;
    width: 100%; }
    .project-card-tall h1.h1-small {
      font-size: 23px; }
    .project-card-tall p.p-small {
      font-size: 15px;
      margin-right: 0; }

  .team-name-card {
    padding: 24px 28px; }

  .project-stats {
    margin-bottom: 36px; }
    .project-stats .wwu-body {
      padding: 32px 24px 36px; }

  cite.mrg-bottom {
    margin-bottom: 54px; }

  header.home-hero-nav {
    max-height: 372px; }
    header.home-hero-nav img.logo-home {
      margin-top: 40px;
      max-width: 150px; }
    header.home-hero-nav nav#navigation_home {
      margin-top: 64px; }
    header.home-hero-nav nav ul li {
      margin: 0 8px; }
      header.home-hero-nav nav ul li:first-child {
        margin-left: 0; }
      header.home-hero-nav nav ul li:last-child {
        margin-right: 0; } }
@media screen and (max-width: 620px) {
  body.home header.home-hero-nav {
    display: none; }

  body.home header.site-header {
    display: block; }

  .cards-4up .card .card-meta {
    min-height: 64px; }

  main .team-member-picture {
    width: 28%; }

  main .team-member-details {
    width: 67.5%; } }
@media screen and (max-width: 600px) {
  .container, .container-wide {
    padding: 0 26px; }

  .container-pad-top {
    padding-top: 32px; }

  .home-section-division h2 {
    font-size: 28px; }

  .work-with-us .wwu-top, .project-stats .wwu-top {
    background: url(../img/work-with-us-sm.png) 50% 0 no-repeat;
    background-size: 100%; }
  .work-with-us .wwu-body, .project-stats .wwu-body {
    padding: 38px 40px 46px; }

  .cards-4up {
    margin: 0 -2.25% 6px -2.25%; }
    .cards-4up .card {
      width: 45.5%;
      margin: 0 2.25% 0px; } }
@media screen and (max-width: 595px) {
  .project-card-tall {
    margin-top: 24px; }
    .project-card-tall h1.h1-small {
      font-size: 22px; } }
@media screen and (max-width: 590px) {
  body.team .cards-2up {
    margin-bottom: 0px; }
    body.team .cards-2up .card, body.team .cards-2up .card-right {
      clear: both;
      float: none;
      width: auto; } }
@media screen and (max-width: 550px) {
  main p.team-bio {
    margin-right: 3.0%;
    margin-bottom: 32px;
    font-size: 1.01em;
    line-height: 1.58em; } }
@media screen and (max-width: 540px) {
  .cards-3up .card {
    width: 47.0%;
    margin-bottom: 26px; }
  .cards-3up img {
    margin-bottom: 14px; }

  body.commercial .hero {
    height: auto;
    padding-bottom: 32px; } }
@media screen and (max-width: 520px) {
  .home-section-division h2 {
    font-size: 26px;
    margin-bottom: 36px; } }
@media screen and (max-width: 500px) {
  h1 {
    font-size: 32px; }

  p.intro {
    font-size: 17px; }

  .team-member h1 {
    font-size: 30px; }

  main .team-member-picture {
    display: none; }

  main .team-member-details {
    float: none;
    width: auto; }

  main .team-member img.team-picture-inline {
    display: block;
    float: right;
    max-width: 180px;
    margin: 0 2.0% 32px 32px; }

  /* Project Page Styles */
  .hero {
    height: 300px; }

  .project-card, .team-name-card {
    margin: 50px 0 0 0;
    width: 91%;
    padding: 28px 32px 36px; }
    .project-card h1, .team-name-card h1 {
      font-size: 26px; }
    .project-card h1.mrg-bottom, .team-name-card h1.mrg-bottom {
      margin-bottom: 20px; }
    .project-card h1.h1-small, .team-name-card h1.h1-small {
      font-size: 24px; }
    .project-card p, .team-name-card p {
      font-size: 16px;
      margin: 0 10px 0 0; }
    .project-card p.p-small, .team-name-card p.p-small {
      font-size: 15px; }

  .project-card-tall {
    margin-top: 30px;
    width: 100%; }
    .project-card-tall h1.h1-small {
      font-size: 22px; }
    .project-card-tall p.p-small {
      font-size: 15px;
      margin-right: 0; }

  .project-stats {
    margin-bottom: 34px; }
    .project-stats .wwu-body {
      padding: 32px 18px 36px; }

  .card-grid-flush {
    margin-bottom: 36px; }

  blockquote {
    font-size: 18px;
    margin-bottom: 28px; }

  cite.mrg-bottom {
    margin-bottom: 52px; }

  footer .divider {
    display: block;
    color: #fff;
    height: 14px;
    overflow: hidden; } }
@media screen and (max-width: 480px) {
  .container, .container-wide {
    padding: 0 24px; }

  .container-pad-top {
    padding-top: 32px; }

  .project-card, .team-name-card {
    width: 98%; } }
@media screen and (max-width: 450px) {
  .logo img {
    max-width: 256px; }

  .nav-toggle {
    font-size: 14px; } }
@media screen and (max-width: 420px) {
  .featured-projects {
    margin: 36px 0; }

  .home-section-division {
    padding: 52px 0;
    margin-top: 40px; }
    .home-section-division h2 {
      font-size: 25px; }

  .cards-4up {
    margin: 0 -2.25% 6px -2.25%; }
    .cards-4up .card {
      width: 45.5%;
      margin: 0 2.25% 0px; }

  .cards-4up .card .card-meta {
    min-height: 72px; } }
@media screen and (max-width: 380px) {
  .logo img {
    max-width: 235px; }

  button.nav-toggle {
    font-size: 13px;
    padding: 8px 8px; } }
/* Team Intro Paragraph */
.team-description-block {
  display: none; }

body.team-overview .team-description-block {
  display: block; }

/* Team Member Banners */
.hero-team-management {
  background: url(../img/team/holland-companies-lg.jpg) top center no-repeat;
  background-size: cover; }

.hero-team-john-holland {
  background: url(../img/team/john-holland-lg.jpg) top center no-repeat;
  background-size: cover; }

.hero-team-joseph-holland {
  background: url(../img/team/joseph-holland-lg.jpg) top center no-repeat;
  background-size: cover; }

.hero-team-mike-holland {
  background: url(../img/team/mike-holland-lg.jpg) top center no-repeat;
  background-size: cover; }

.hero-team-paul-holland {
  background: url(../img/team/paul-holland-lg.jpg) top center no-repeat;
  background-size: cover; }

p.team-bio {
  margin-bottom: 38px;
  margin-right: 10.0%;
  font-size: 1.05em;
  line-height: 1.62em; }

p.team-contact {
  margin-bottom: 60px; }

.team-member {
  margin: 0; }

.team-member-picture {
  float: left;
  width: 22.0%; }

.team-member-details {
  float: right;
  width: 74.5%; }

.team-member img.team-picture-inline {
  display: none; }

/*!
	Modaal - accessible modals - v0.4.4
	by Humaan, for all humans.
	http://humaan.com
 */
.modaal-noscroll {
  overflow: hidden; }

.modaal-accessible-hide {
  position: absolute !important;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden; }

.modaal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  opacity: 0; }

.modaal-wrapper {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  overflow: auto;
  opacity: 1;
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
  transition: all 0.3s ease-in-out; }
  .modaal-wrapper * {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-backface-visibility: hidden; }
  .modaal-wrapper .modaal-close {
    border: none;
    background: transparent;
    padding: 0;
    -webkit-appearance: none; }
  .modaal-wrapper.modaal-start_none {
    display: none;
    opacity: 1; }
  .modaal-wrapper.modaal-start_fade {
    opacity: 0; }
  .modaal-wrapper *[tabindex="0"] {
    outline: none !important; }
  .modaal-wrapper.modaal-fullscreen {
    overflow: hidden; }

.modaal-outer-wrapper {
  display: table;
  position: relative;
  width: 100%;
  height: 100%; }
  .modaal-fullscreen .modaal-outer-wrapper {
    display: block; }

.modaal-inner-wrapper {
  display: table-cell;
  width: 100%;
  height: 100%;
  position: relative;
  vertical-align: middle;
  text-align: center;
  padding: 80px 25px; }
  .modaal-fullscreen .modaal-inner-wrapper {
    padding: 0;
    display: block;
    vertical-align: top; }

.modaal-container {
  position: relative;
  display: inline-block;
  width: 100%;
  margin: auto;
  text-align: left;
  color: #000;
  max-width: 1000px;
  border-radius: 0px;
  background: #fff;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  cursor: auto; }
  .modaal-container.is_loading {
    height: 100px;
    width: 100px;
    overflow: hidden; }
  .modaal-fullscreen .modaal-container {
    max-width: none;
    height: 100%;
    overflow: auto; }

.modaal-close {
  position: fixed;
  right: 20px;
  top: 20px;
  color: #fff;
  cursor: pointer;
  opacity: 1;
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0);
  border-radius: 100%;
  transition: all 0.2s ease-in-out; }
  .modaal-close:focus, .modaal-close:hover {
    outline: none;
    background: #fff; }
    .modaal-close:focus:before, .modaal-close:focus:after, .modaal-close:hover:before, .modaal-close:hover:after {
      background: #b93d0c; }
  .modaal-close span {
    position: absolute !important;
    clip: rect(1px 1px 1px 1px);
    /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0 !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden; }
  .modaal-close:before, .modaal-close:after {
    display: block;
    content: " ";
    position: absolute;
    top: 14px;
    left: 23px;
    width: 4px;
    height: 22px;
    border-radius: 4px;
    background: #fff;
    transition: background 0.2s ease-in-out; }
  .modaal-close:before {
    transform: rotate(-45deg); }
  .modaal-close:after {
    transform: rotate(45deg); }
  .modaal-fullscreen .modaal-close {
    background: #afb7bc;
    right: 10px;
    top: 10px; }

.modaal-content-container {
  padding: 30px; }

.modaal-confirm-wrap {
  padding: 30px 0 0;
  text-align: center;
  font-size: 0; }

.modaal-confirm-btn {
  font-size: 14px;
  display: inline-block;
  margin: 0 10px;
  vertical-align: middle;
  cursor: pointer;
  border: none;
  background: transparent; }
  .modaal-confirm-btn.modaal-ok {
    padding: 10px 15px;
    color: #fff;
    background: #555;
    border-radius: 3px;
    transition: background 0.2s ease-in-out; }
    .modaal-confirm-btn.modaal-ok:hover {
      background: #2f2f2f; }
  .modaal-confirm-btn.modaal-cancel {
    text-decoration: underline; }
    .modaal-confirm-btn.modaal-cancel:hover {
      text-decoration: none;
      color: #2f2f2f; }

@keyframes instaReveal {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
@-o-keyframes instaReveal {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
@-moz-keyframes instaReveal {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
@-webkit-keyframes instaReveal {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
@-ms-keyframes instaReveal {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }
.modaal-instagram .modaal-container {
  width: auto;
  background: transparent;
  box-shadow: none !important; }
.modaal-instagram .modaal-content-container {
  padding: 0;
  background: transparent; }
.modaal-instagram .modaal-content-container > blockquote {
  width: 1px !important;
  height: 1px !important;
  opacity: 0 !important; }
.modaal-instagram iframe {
  opacity: 0;
  margin: -6px !important;
  border-radius: 0 !important;
  width: 1000px !important;
  max-width: 800px !important;
  box-shadow: none !important;
  animation: instaReveal 1s linear forwards; }

.modaal-image .modaal-inner-wrapper {
  padding-left: 140px;
  padding-right: 140px; }
.modaal-image .modaal-container {
  width: auto;
  max-width: 100%; }

.modaal-gallery-wrap {
  position: relative;
  color: #fff; }

.modaal-gallery-item {
  display: none; }
  .modaal-gallery-item img {
    display: block; }
  .modaal-gallery-item.is_active {
    display: block; }

.modaal-gallery-label {
  position: absolute;
  left: 0;
  width: 100%;
  margin: 20px 0 0;
  font-size: 18px;
  text-align: center;
  color: #fff; }
  .modaal-gallery-label:focus {
    outline: none; }

.modaal-gallery-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  opacity: 1;
  cursor: pointer;
  color: #fff;
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0);
  border: none;
  border-radius: 100%;
  transition: all 0.2s ease-in-out; }
  .modaal-gallery-control.is_hidden {
    opacity: 0;
    cursor: default; }
  .modaal-gallery-control:focus, .modaal-gallery-control:hover {
    outline: none;
    background: #fff; }
    .modaal-gallery-control:focus:before, .modaal-gallery-control:focus:after, .modaal-gallery-control:hover:before, .modaal-gallery-control:hover:after {
      background: #afb7bc; }
  .modaal-gallery-control span {
    position: absolute !important;
    clip: rect(1px 1px 1px 1px);
    /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0 !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden; }
  .modaal-gallery-control:before, .modaal-gallery-control:after {
    display: block;
    content: " ";
    position: absolute;
    top: 16px;
    left: 25px;
    width: 4px;
    height: 18px;
    border-radius: 4px;
    background: #fff;
    transition: background 0.2s ease-in-out; }
  .modaal-gallery-control:before {
    margin: -5px 0 0;
    transform: rotate(-45deg); }
  .modaal-gallery-control:after {
    margin: 5px 0 0;
    transform: rotate(45deg); }

.modaal-gallery-next-inner {
  left: 100%;
  margin-left: 40px; }
.modaal-gallery-next-outer {
  right: 45px; }

.modaal-gallery-prev:before, .modaal-gallery-prev:after {
  left: 22px; }
.modaal-gallery-prev:before {
  margin: 5px 0 0;
  transform: rotate(-45deg); }
.modaal-gallery-prev:after {
  margin: -5px 0 0;
  transform: rotate(45deg); }
.modaal-gallery-prev-inner {
  right: 100%;
  margin-right: 40px; }
.modaal-gallery-prev-outer {
  left: 45px; }

.modaal-video-wrap {
  margin: auto 50px;
  position: relative; }

.modaal-video-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  background: #000;
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto; }
  .modaal-video-container iframe,
  .modaal-video-container object,
  .modaal-video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.modaal-iframe .modaal-content {
  width: 100%;
  height: 100%; }

.modaal-iframe-elem {
  width: 100%;
  height: 100%;
  display: block; }

@media only screen and (min-width: 1400px) {
  .modaal-video-container {
    padding-bottom: 0;
    height: 731px; } }
@media only screen and (max-width: 1140px) {
  .modaal-image .modaal-inner-wrapper {
    padding-left: 25px;
    padding-right: 25px; }

  .modaal-gallery-control {
    top: auto;
    bottom: 20px;
    transform: none;
    background: rgba(0, 0, 0, 0.7); }
    .modaal-gallery-control:before, .modaal-gallery-control:after {
      background: #fff; }

  .modaal-gallery-next {
    left: auto;
    right: 20px; }

  .modaal-gallery-prev {
    left: 20px;
    right: auto; } }
@media screen and (max-width: 900px) {
  .modaal-instagram iframe {
    width: 500px !important; } }
@media screen and (max-height: 1100px) {
  .modaal-instagram iframe {
    width: 700px !important; } }
@media screen and (max-height: 1000px) {
  .modaal-inner-wrapper {
    padding-top: 60px;
    padding-bottom: 60px; }

  .modaal-instagram iframe {
    width: 600px !important; } }
@media screen and (max-height: 900px) {
  .modaal-instagram iframe {
    width: 500px !important; }

  .modaal-video-container {
    max-width: 900px;
    max-height: 510px; } }
@media only screen and (max-width: 600px) {
  .modaal-instagram iframe {
    width: 280px !important; } }
@media only screen and (max-height: 820px) {
  .modaal-gallery-label {
    display: none; } }
.modaal-loading-spinner {
  background: none;
  position: absolute;
  width: 200px;
  height: 200px;
  top: 50%;
  left: 50%;
  margin: -100px 0 0 -100px;
  transform: scale(0.25); }

@-ms-keyframes modaal-loading-spinner {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5); }
  100% {
    opacity: .1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); } }
@-moz-keyframes modaal-loading-spinner {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5); }
  100% {
    opacity: .1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); } }
@-webkit-keyframes modaal-loading-spinner {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5); }
  100% {
    opacity: .1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); } }
@-o-keyframes modaal-loading-spinner {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5); }
  100% {
    opacity: .1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); } }
@keyframes modaal-loading-spinner {
  0% {
    opacity: 1;
    -ms-transform: scale(1.5);
    -moz-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    -o-transform: scale(1.5);
    transform: scale(1.5); }
  100% {
    opacity: .1;
    -ms-transform: scale(1);
    -moz-transform: scale(1);
    -webkit-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); } }
.modaal-loading-spinner > div {
  width: 24px;
  height: 24px;
  margin-left: 4px;
  margin-top: 4px;
  position: absolute; }

.modaal-loading-spinner > div > div {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background: #fff; }

.modaal-loading-spinner > div:nth-of-type(1) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite;
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: 0s;
  -moz-animation-delay: 0s;
  -webkit-animation-delay: 0s;
  -o-animation-delay: 0s;
  animation-delay: 0s; }

.modaal-loading-spinner > div:nth-of-type(2) > div, .modaal-loading-spinner > div:nth-of-type(3) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite; }

.modaal-loading-spinner > div:nth-of-type(1) {
  -ms-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(45deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(45deg) translate(70px, 0); }

.modaal-loading-spinner > div:nth-of-type(2) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: .12s;
  -moz-animation-delay: .12s;
  -webkit-animation-delay: .12s;
  -o-animation-delay: .12s;
  animation-delay: .12s; }

.modaal-loading-spinner > div:nth-of-type(2) {
  -ms-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(90deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(90deg) translate(70px, 0); }

.modaal-loading-spinner > div:nth-of-type(3) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: .25s;
  -moz-animation-delay: .25s;
  -webkit-animation-delay: .25s;
  -o-animation-delay: .25s;
  animation-delay: .25s; }

.modaal-loading-spinner > div:nth-of-type(4) > div, .modaal-loading-spinner > div:nth-of-type(5) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite; }

.modaal-loading-spinner > div:nth-of-type(3) {
  -ms-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(135deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(135deg) translate(70px, 0); }

.modaal-loading-spinner > div:nth-of-type(4) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: .37s;
  -moz-animation-delay: .37s;
  -webkit-animation-delay: .37s;
  -o-animation-delay: .37s;
  animation-delay: .37s; }

.modaal-loading-spinner > div:nth-of-type(4) {
  -ms-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(180deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(180deg) translate(70px, 0); }

.modaal-loading-spinner > div:nth-of-type(5) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: .5s;
  -moz-animation-delay: .5s;
  -webkit-animation-delay: .5s;
  -o-animation-delay: .5s;
  animation-delay: .5s; }

.modaal-loading-spinner > div:nth-of-type(6) > div, .modaal-loading-spinner > div:nth-of-type(7) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite; }

.modaal-loading-spinner > div:nth-of-type(5) {
  -ms-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(225deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(225deg) translate(70px, 0); }

.modaal-loading-spinner > div:nth-of-type(6) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: .62s;
  -moz-animation-delay: .62s;
  -webkit-animation-delay: .62s;
  -o-animation-delay: .62s;
  animation-delay: .62s; }

.modaal-loading-spinner > div:nth-of-type(6) {
  -ms-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(270deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(270deg) translate(70px, 0); }

.modaal-loading-spinner > div:nth-of-type(7) > div {
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: .75s;
  -moz-animation-delay: .75s;
  -webkit-animation-delay: .75s;
  -o-animation-delay: .75s;
  animation-delay: .75s; }

.modaal-loading-spinner > div:nth-of-type(7) {
  -ms-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(315deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(315deg) translate(70px, 0); }

.modaal-loading-spinner > div:nth-of-type(8) > div {
  -ms-animation: modaal-loading-spinner 1s linear infinite;
  -moz-animation: modaal-loading-spinner 1s linear infinite;
  -webkit-animation: modaal-loading-spinner 1s linear infinite;
  -o-animation: modaal-loading-spinner 1s linear infinite;
  animation: modaal-loading-spinner 1s linear infinite;
  -ms-animation-delay: .87s;
  -moz-animation-delay: .87s;
  -webkit-animation-delay: .87s;
  -o-animation-delay: .87s;
  animation-delay: .87s; }

.modaal-loading-spinner > div:nth-of-type(8) {
  -ms-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
  -moz-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
  -webkit-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
  -o-transform: translate(84px, 84px) rotate(360deg) translate(70px, 0);
  transform: translate(84px, 84px) rotate(360deg) translate(70px, 0); }
